import { AsyncValue } from "./lifecycle";

export interface Snack {
  id: number;
  text: string;
}

export type ReferenceList = { [key: string]: { html: string } };

export interface SavedForm {
  id: string;
  name: string;
  restoreGuid: string;
  date: string;
}

export interface SentForm {
  id: string;
  name: string;
  restoreGuid: string;
  date: string;
  frmRef?: string;
  hasPdf: boolean;
  pdfUrl: string;
}

export interface UsedForm {
  id: string;
  name: string;
}

export interface Forms<T> {
  forms: T[];
  more: boolean;
}

export interface StoreModel {
  errors: Array<Snack>;
  reference: AsyncValue<ReferenceList>;
  allForms: AsyncValue<Forms<SentForm>>;
  savedForms: AsyncValue<Forms<SavedForm>>;
  sentForms: AsyncValue<Forms<SentForm>>;
  usedForms: AsyncValue<Forms<UsedForm>>;
}

export interface Config {
  digid: boolean;
  eherkenning: boolean;
  eidas: boolean;
  alert?: string;
}

export const DefaultConfig: Config = {
  digid: true,
  eherkenning: true,
  eidas: true,
};
