import * as React from "react";

import { Clickable, Html, Label, SubType } from "../business/interfaces";

import Box from "../atoms/box";
import Button from "../atoms/button";
import Heading from "../atoms/heading";
import RichContent from "../atoms/richContent";
import Wrap from "../atoms/wrap";

export type DataProps = Label &
  Html & {
    login: Clickable & Label;
  };

export type Props = DataProps & SubType;

const LoginBox = React.memo<Props>(({ label, html, login, subType }) => (
  <Box subType={`login-${subType}`}>
    <Heading level="h3" label={label} />
    <Wrap subType="body">
      <Button subType="login" {...login} />
      <RichContent subType="info" html={html} />
    </Wrap>
  </Box>
));

export default LoginBox;
