import * as React from "react";

import { Children, SubType } from "../business/interfaces";
import { subClass } from "../util/classNames";

export type Props = SubType & Children;

const Section = React.memo<Props>(({ subType, children }) => (
  <section className={subClass("section", subType)}>{children}</section>
));

export default Section;
