import { AnyAction } from "redux";
import { put } from "redux-saga/effects";

export interface AsyncStatus {
  loading: boolean;
}

export interface AsyncBase {
  status: AsyncStatus;
  expiry?: number;
  value?: any;
}

export interface AsyncIdBase {
  [id: string]: AsyncBase;
}

export interface AsyncValue<T> extends AsyncBase {
  value?: T;
}

export type AsyncEvent =
  | "start"
  | "success"
  | "error"
  | "update"
  | "invalidate"
  | "clear";

export const initial: AsyncStatus = Object.freeze({
  loading: false,
});
const loading: AsyncStatus = Object.freeze({
  loading: true,
});

export interface AsyncOptions {
  onSuccess?: (action: AnyAction, payload: any) => any;
}

const emptyResult = Object.freeze({ payload: undefined, exception: undefined });
export const asyncLifecycle = (
  prefix: string,
  fire: (action: AnyAction) => Promise<any>,
  options?: AsyncOptions
) =>
  function* (action: AnyAction) {
    yield put({ ...action, type: `${prefix}_START` });
    try {
      const result = (yield fire(action)) as Record<string, object>;
      const event = "payload" in result ? "SUCCESS" : "ERROR";
      if (event === "SUCCESS" && options && options.onSuccess) {
        yield* options.onSuccess(action, result.payload);
      }
      yield put({
        ...action,
        ...emptyResult,
        ...result,
        type: `${prefix}_${event}`,
      });
    } catch (e) {
      yield put({
        ...action,
        ...emptyResult,
        exception: e,
        type: `${prefix}_ERROR`,
      });
    }
  };

const nextStatus: (status: AsyncStatus, event: AsyncEvent) => AsyncStatus = (
  status: AsyncStatus,
  event: AsyncEvent
) => {
  switch (event) {
    case "start":
    case "update":
      return loading;
    case "success":
    case "clear":
    case "error":
      return initial;
    case "invalidate":
      return status;
    default:
      return status;
  }
};

const nextExpiry: (
  expiry: number | undefined,
  event: AsyncEvent
) => number | undefined = (expiry, event) => {
  switch (event) {
    case "start":
    case "clear":
    case "error":
      return undefined;
    case "success":
      return new Date().valueOf() + 30000;
    case "update":
    case "invalidate":
      return new Date().valueOf();
    default:
      return expiry;
  }
};

export const next: (
  state: AsyncBase,
  event: AsyncEvent,
  payload: any
) => AsyncBase = (state: AsyncBase, event: AsyncEvent, payload: any) => {
  const status = nextStatus(state.status, event);
  const expiry = nextExpiry(state.expiry, event);
  const value =
    event === "error" || event === "clear" ? undefined : payload || state.value;
  return status === state.status &&
    expiry === state.expiry &&
    value === state.value
    ? state
    : { status, expiry, value };
};

export const patch: (
  state: AsyncIdBase,
  id: string,
  next: AsyncBase
) => AsyncIdBase = (state, id, next) =>
  state[id] === next ? state : { ...state, [id]: next };

export const initialState = Object.freeze({
  status: initial,
  value: undefined,
});

export const asyncReducer =
  (prefix: string) =>
  (state: AsyncBase | undefined, { type, payload }: AnyAction) => {
    const [p, e] = type.split("_");
    return p !== prefix
      ? state || initialState
      : next(state || initialState, e.toLowerCase() as AsyncEvent, payload);
  };

const initialIdState = Object.freeze({});

export const asyncIdReducer =
  (prefix: string) =>
  (state: AsyncIdBase | undefined, { type, id, payload }: AnyAction) => {
    const [p, e] = type.split("_");
    const safeState = (state || initialIdState) as AsyncIdBase;
    if (p !== prefix) {
      return safeState;
    }

    const stringId = id.toString();
    return patch(
      safeState,
      stringId,
      next(
        safeState[stringId] || initialState,
        e.toLowerCase() as AsyncEvent,
        payload
      )
    );
  };

export function composeReducers<TState>(
  ...reducers: Array<(state: TState, action: AnyAction) => TState>
) {
  return (state: TState, action: AnyAction) =>
    reducers.reduce((state, reducer) => reducer(state, action), state);
}

export function immediateReducer<T>(prefix: string, initialValue: T) {
  return (state: T | undefined, { type, payload }: AnyAction) =>
    (type === `${prefix}_SET` ? payload : state) || initialValue;
}
