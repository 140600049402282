import * as React from "react";
import Wrap from "../atoms/wrap";
import FormsZone, { Props as FormsProps } from "../zones/formsZone";
import IntroZone, { Props as IntroProps } from "../zones/introZone";

export interface Props {
  forms: FormsProps;
  intro: IntroProps;
  require: () => void;
}

class OverviewPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    props.require();
  }

  render = () => {
    const { forms, intro } = this.props;
    return (
      <Wrap subType="page-overview">
        <IntroZone {...intro} />
        <FormsZone {...forms} />
      </Wrap>
    );
  };
}

export default OverviewPage;
