import * as React from "react";

import { Children, SubType } from "../business/interfaces";

import { subClass } from "../util/classNames";

export type Props = SubType & Children;

const Wrap = React.memo<Props>(({ subType, children }) => (
  <div className={subClass("wrap", subType)}>{children}</div>
));

export default Wrap;
