import * as React from "react";
import { Href, Html, Label } from "../business/interfaces";
import Section from "../atoms/section";
import RichContent from "../atoms/richContent";
import Link from "../atoms/link";

export type Props = Html & {
  help: Label & Href;
  cancel: Label & Href;
};

const IntroZone = React.memo<Props>(({ html, help, cancel }) => (
  <Section subType="zone-intro">
    <RichContent subType="intro" html={html} />
    <Link subType="help" {...help} />
    <Link subType="cancel" {...cancel} />
  </Section>
));

export default IntroZone;
