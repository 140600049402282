import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Reload from "../atoms/reload";
import Spinner from "../atoms/spinner";
import {
  Forms,
  ReferenceList,
  SavedForm,
  SentForm,
  StoreModel,
  UsedForm,
} from "../business/models";
import OverviewPage, { Props as OverviewProps } from "../pages/overviewPage";
import SentPage, { Props as AllProps } from "../pages/sentPage";
import { Props as BackProps } from "../zones/backZone";
import { Props as FormsProps } from "../zones/formsZone";
import { Props as IntroProps } from "../zones/introZone";
import { Props as SentProps } from "../zones/sentZone";
import { parseDate } from "../util/date";
import { KlantportaalRouter } from "../boot";
import { Route } from "react-router";

type Props = { overview?: OverviewProps; sent?: AllProps };

const Component = React.memo<Props>(({ overview, sent }) => (
  <KlantportaalRouter>
    <>
      {overview === undefined && (
        <Spinner label="Even geduld, het klantportaal wordt geladen." />
      )}
      {overview !== undefined && (
        <Route exact path="/" render={() => <OverviewPage {...overview} />} />
      )}
      {sent !== undefined && (
        <Route exact path="/verzonden" render={() => <SentPage {...sent} />} />
      )}
      <Route component={Reload} />
    </>
  </KlantportaalRouter>
));

const toIntroProps: (reference: ReferenceList) => IntroProps = (reference) => {
  const htmlOrDefault = (alias: string) =>
    alias in reference ? reference[alias].html : alias;
  const link = (subType: string) => ({
    label: htmlOrDefault(`links_${subType}_label`),
    href: htmlOrDefault(`links_${subType}_link`),
  });
  return {
    html: htmlOrDefault("overview_intro"),
    help: link("help"),
    cancel: link("cancel"),
  };
};

const toFormsProps: (
  reference: ReferenceList,
  saved: Forms<SavedForm> | undefined,
  sent: Forms<SentForm> | undefined,
  used: Forms<UsedForm> | undefined
) => FormsProps = (reference, saved, sent, used) => {
  const htmlOrDefault = (alias: string) =>
    alias in reference ? reference[alias].html : alias;
  const link = (subType: string) => ({
    label: htmlOrDefault(`links_${subType}_label`),
    href: htmlOrDefault(`links_${subType}_link`),
  });
  return {
    patience: htmlOrDefault("forms_patience"),
    savedForms: {
      label: htmlOrDefault("forms_saved_label"),
      savedForms:
        saved === undefined
          ? undefined
          : saved.forms.map(({ id, name, restoreGuid, date }) => ({
              label: name,
              href: `/Form.aspx?src=${id}&restore=${restoreGuid}`,
              dateTime: parseDate(date),
            })),
    },
    sentForms: {
      label: htmlOrDefault("forms_sent_label"),
      sentForms:
        sent === undefined
          ? undefined
          : sent.forms.map(
              ({ id, name, frmRef, pdfUrl, restoreGuid, date }) => ({
                label: name,
                formReference: frmRef
                  ? {
                      value: frmRef,
                      label: htmlOrDefault("form_reference_label"),
                    }
                  : undefined,
                dateTime: parseDate(date),
                add: link("add"),
                cancel: link("cancel"),
                download: {
                  label: htmlOrDefault("links_download_label"),
                  href: `${pdfUrl}?restore=${restoreGuid}`,
                },
              })
            ),
      more:
        sent === undefined || !sent.more
          ? undefined
          : {
              label: htmlOrDefault("forms_sent_more"),
              onClick: () => console.log("More sent forms..."),
            },
    },
    usedForms: {
      label: htmlOrDefault("forms_used_label"),
      usedForms:
        used === undefined
          ? undefined
          : used.forms.map(({ id, name }) => ({
              label: name,
              href: `/Form.aspx?src=${id}`,
            })),
    },
  };
};

const mergeMore: (
  props: OverviewProps,
  onClick: () => void
) => OverviewProps = (
  {
    forms: {
      sentForms: { more, ...sentForms },
      ...forms
    },
    ...overview
  },
  onClick
) => ({
  ...overview,
  forms: {
    ...forms,
    sentForms: {
      ...sentForms,
      more: more === undefined ? undefined : { ...more, onClick },
    },
  },
});

const toBackProps: (reference: ReferenceList) => BackProps = (reference) => {
  const htmlOrDefault = (alias: string) =>
    alias in reference ? reference[alias].html : alias;
  return {
    back: { label: htmlOrDefault("links_back_label") },
  };
};

const toSentProps: (
  reference: ReferenceList,
  sent: Forms<SentForm> | undefined
) => SentProps = (reference, sent) => {
  const htmlOrDefault = (alias: string) =>
    alias in reference ? reference[alias].html : alias;
  const link = (subType: string) => ({
    label: htmlOrDefault(`links_${subType}_label`),
    href: htmlOrDefault(`links_${subType}_link`),
  });
  return {
    patience: htmlOrDefault("forms_patience"),
    sentForms: {
      label: htmlOrDefault("forms_sent_label"),
      sentForms:
        sent === undefined
          ? undefined
          : sent.forms.map(
              ({ id, name, frmRef, pdfUrl, restoreGuid, date }) => ({
                label: name,
                formReference: frmRef
                  ? {
                      value: frmRef,
                      label: htmlOrDefault("form_reference_label"),
                    }
                  : undefined,
                dateTime: parseDate(date),
                add: link("add"),
                cancel: link("cancel"),
                download: {
                  label: htmlOrDefault("links_download_label"),
                  href: `${pdfUrl}?restore=${restoreGuid}`,
                },
              })
            ),
    },
  };
};

const emptyObject = Object.freeze({});
const OverviewContainer = connect(
  ({
    reference: { value },
    allForms: { value: all },
    savedForms: { value: saved },
    sentForms: { value: sent },
    usedForms: { value: used },
  }: StoreModel) =>
    (value === undefined
      ? emptyObject
      : {
          overview: {
            intro: toIntroProps(value),
            forms: toFormsProps(value, saved, sent, used),
          },
          sent: {
            back: toBackProps(value),
            sent: toSentProps(value, all),
          },
        }) as Props,
  (dispatch) => ({
    more: () => dispatch(push("/verzonden")),
    requireOverview: () => {
      dispatch({ type: "SAVED_REQUIRE" });
      dispatch({ type: "SENT_REQUIRE" });
      dispatch({ type: "USED_REQUIRE" });
    },
    requireSent: () => dispatch({ type: "ALL_REQUIRE" }),
  }),
  (stateProps, { more, requireOverview, requireSent }, ownProps) =>
    stateProps.overview !== undefined
      ? {
          ...ownProps,
          overview: {
            ...mergeMore(stateProps.overview, more),
            require: requireOverview,
          },
          sent: { ...stateProps.sent, require: requireSent },
        }
      : ownProps
)(Component);

export default OverviewContainer;
