import { createHashHistory, History } from "history";
import { routerMiddleware } from "react-router-redux";
import { HashRouter } from "react-router-dom";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from "redux";
import createSagaMiddleware from "redux-saga";

import { sagas } from "./business/sagas";
import { asyncReducer } from "./business/lifecycle";
import { errorReducer } from "./business/reducers";

export const history: History = createHashHistory();
export class KlantportaalRouter extends HashRouter {
  constructor(props: any) {
    super(props);
    this.history = history;
  }

  history: History;
}

const boot: (noInit?: boolean) => Store<{}, AnyAction> = (
  noInit: boolean = false
) => {
  const sagaMiddleware = createSagaMiddleware();
  const reducer = combineReducers({
    errors: errorReducer("ERROR"),
    reference: asyncReducer("REFERENCE"),
    allForms: asyncReducer("ALL"),
    savedForms: asyncReducer("SAVED"),
    sentForms: asyncReducer("SENT"),
    usedForms: asyncReducer("USED"),
  });
  const extensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers =
    (process.env.NODE_ENV === "development" && extensionCompose) || compose;
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );
  sagaMiddleware.run(sagas);
  if (noInit) {
    return store;
  }

  store.dispatch({ type: "REFERENCE_REQUIRE" });
  return store;
};

export default boot;
