const IproxFormsKlantportaalPrefix = "ifk";

export const superClass = (component: string) =>
  `${IproxFormsKlantportaalPrefix}-${component}`;

export const subClass = (component: string, subType: string) => {
  const superClassName = superClass(component);
  const all = [superClassName];
  let className = superClassName;
  for (const part of subType.split("-")) {
    className = `${className}-${part}`;
    all.push(className);
  }

  return all.join(" ");
};

export const loginAlertToSubType = (value: string) => {
  switch (true) {
    case value && value.startsWith("Digid"):
      return `${value.substring(5).toLowerCase()}-digid`;
    case value && value.startsWith("Eherkenning"):
      return `${value.substring(11).toLowerCase()}-eherkenning`;
    default:
      return "";
  }
};
