import format from "date-fns/format";
import parse from "date-fns/parse";

// 20-04-2017 09:05
// 0123456789012345678
// 2014-02-11T11:30:30
const isoDate = (date: string) => {
  const day = [
    date.substring(6, 10),
    date.substring(3, 5),
    date.substring(0, 2),
  ].join("-");
  const time = `${date.substring(11, 13)}:${date.substring(14, 16)}:00`;
  return `${day}T${time}`;
};

export const parseDate = (date: string) => parse(isoDate(date));

export const formatDate = (date: Date) => format(date, "DD-MM-YYYY HH:mm");
