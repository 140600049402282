import * as React from "react";

import { Clickable, Label, SubType } from "../business/interfaces";

import { subClass } from "../util/classNames";

export type Props = Clickable & Label & SubType;

const Button = React.memo<Props>(({ label, onClick, subType }) => (
  <button
    className={subClass("button", subType)}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    {label}
  </button>
));

export default Button;
