import * as React from "react";
import { Html, Label } from "../business/interfaces";
import LoginBox, { DataProps as Login } from "../molecules/loginBox";
import Section from "../atoms/section";
import Heading from "../atoms/heading";
import RichContent from "../atoms/richContent";
import { ConfigConsumer } from "../business/config";
import Alert from "../atoms/alert";
import { loginAlertToSubType } from "../util/classNames";

export interface Alerts {
  DigidCancel: string;
  DigidError: string;
  EherkenningCancel: string;
  EherkenningError: string;
}

type StringMap = { [index: string]: string };

export type Props = Label &
  Html & {
    digid: Login;
    eherkenning: Login;
    eidas: Login;
    alerts: Alerts;
  };

const LoginZone = React.memo<Props>(
  ({ alerts, label, html, digid, eherkenning, eidas }) => (
    <Section subType="zone-login">
      <ConfigConsumer>
        {(config) => (
          <>
            <Heading level="h2" label={label} />
            {config.alert && (
              <Alert subType={loginAlertToSubType(config.alert)}>
                <RichContent
                  html={(alerts as any as StringMap)[config.alert]}
                  subType="alert-body"
                />
              </Alert>
            )}
            <RichContent subType="intro" html={html} />
            {config.digid && <LoginBox subType="digid" {...digid} />}
            {config.eherkenning && (
              <LoginBox subType="eherkenning" {...eherkenning} />
            )}
            {config.eidas && <LoginBox subType="eidas" {...eidas} />}
          </>
        )}
      </ConfigConsumer>
    </Section>
  )
);

export default LoginZone;
