import * as React from "react";
import LoginZone, { Props as LoginProps } from "../zones/loginZone";
import RequestZone, { Props as RequestProps } from "../zones/requestZone";
import Wrap from "../atoms/wrap";

export type Props = {
  login: LoginProps;
  request: RequestProps;
};

const LoginPage = React.memo<Props>(({ login, request }) => (
  <Wrap subType="page-login">
    <LoginZone {...login} />
    <RequestZone {...request} />
  </Wrap>
));

export default LoginPage;
