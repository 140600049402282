import * as React from "react";
import Section from "../atoms/section";
import { Patience } from "../business/interfaces";
import SentForms, { Props as SentFormsProps } from "../molecules/sentForms";

export type Props = Patience & {
  sentForms: SentFormsProps;
};

const SentZone = React.memo<Props>(({ patience, sentForms }) => (
  <Section subType="zone-sent">
    <SentForms {...sentForms} patience={patience} />
  </Section>
));

export default SentZone;
