import * as React from "react";

import { Href, Label, SubType } from "../business/interfaces";

import { subClass } from "../util/classNames";

export type Props = SubType & Href & Label;

const Link = React.memo<Props>(({ subType, href, label }) => (
  <div className={subClass("link", subType)}>
    <a href={href}>{label}</a>
  </div>
));

export default Link;
