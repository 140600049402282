import * as React from "react";
import { Action, Store } from "redux";
import { Provider } from "react-redux";

import { ConfigProvider } from "../business/config";
import { Config } from "../business/models";

export interface AppProps {
  store: Store<{}, Action>;
  config: Config;
}

const App = React.memo<AppProps & { children: React.ReactChild }>(
  ({ store, config, children }) => (
    <Provider store={store}>
      <ConfigProvider value={config}>{children}</ConfigProvider>
    </Provider>
  )
);

export default App;
