import * as React from "react";
import SavedForms, { Props as SavedFormsProps } from "../molecules/savedForms";
import SentForms, { Props as SentFormsProps } from "../molecules/sentForms";
import UsedForms, { Props as UsedFormsProps } from "../molecules/usedForms";
import { Patience } from "../business/interfaces";

export type Props = Patience & {
  savedForms: SavedFormsProps;
  sentForms: SentFormsProps;
  usedForms: UsedFormsProps;
};

const FormsZone = React.memo<Props>(
  ({ patience, savedForms, sentForms, usedForms }) => (
    <>
      <SavedForms {...savedForms} patience={patience} />
      <UsedForms {...usedForms} patience={patience} />
      <SentForms {...sentForms} patience={patience} />
    </>
  )
);

export default FormsZone;
