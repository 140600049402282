import * as React from "react";
import Section from "../atoms/section";
import { Label } from "../business/interfaces";
import { subClass } from "../util/classNames";

export type Props = {
  back: Label;
};

function goBack(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
  event.preventDefault();
  window.history.back();
}

const noHref = "#";

const BackZone = React.memo<Props>(({ back }) => (
  <Section subType="zone-back">
    <div className={subClass("link", "back")}>
      <a href={noHref} onClick={goBack}>{back.label}</a>
    </div>
  </Section>
));

export default BackZone;
