import * as React from "react";
import { Action, Store } from "redux";
import { Config } from "./business/models";
import App from "./containers/App";
import OverviewContainer from "./containers/overviewContainer";

interface StoreProps {
  store: Store<{}, Action<any>>;
  config: Config;
}

const OverviewApp = React.memo<StoreProps>((props) => (
  <App {...props}>
    <OverviewContainer />
  </App>
));

export default OverviewApp;
