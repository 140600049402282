import * as React from "react";
import { Label } from "../business/interfaces";
import RequestSection, {
  DataProps as Request,
} from "../molecules/requestSection";
import Section from "../atoms/section";
import Heading from "../atoms/heading";
import { ConfigConsumer } from "../business/config";

export type Props = Label & {
  digid: Request;
  eherkenning: Request;
  eidas: Request;
};

const RequestZone = React.memo<Props>(
  ({ label, digid, eherkenning, eidas }) => (
    <Section subType="zone-request">
      <Heading level="h2" label={label} />
      <ConfigConsumer>
        {(config) => (
          <>
            {config.digid && <RequestSection subType="digid" {...digid} />}
            {config.eherkenning && (
              <RequestSection subType="eherkenning" {...eherkenning} />
            )}
            {config.eidas && <RequestSection subType="eidas" {...eidas} />}
          </>
        )}
      </ConfigConsumer>
    </Section>
  )
);

export default RequestZone;
