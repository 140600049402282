import * as React from "react";

import { Html, SubType } from "../business/interfaces";

import { subClass } from "../util/classNames";

export type Props = Html & SubType;

const RichContent = React.memo<Props>(({ html, subType }) => (
  <div
    className={subClass("rich-content", subType)}
    dangerouslySetInnerHTML={{ __html: html }}
  />
));

export default RichContent;
