import * as React from "react";

import Heading from "../atoms/heading";
import List from "../atoms/list";
import Section from "../atoms/section";
import Spinner from "../atoms/spinner";
import { Label, Patience } from "../business/interfaces";
import UsedForm, { Props as UsedFormProps } from "./usedForm";

export type Props = Label & {
  usedForms?: UsedFormProps[];
};

const UsedForms = React.memo<Patience & Props>(
  ({ label, patience, usedForms }) => (
    <Section subType="forms-used">
      <Heading level="h2" label={label} />
      <List subType="forms-used">
        {usedForms === undefined ? (
          <Spinner label={patience} />
        ) : (
          usedForms.map((form) => <UsedForm key={form.href} {...form} />)
        )}
      </List>
    </Section>
  )
);

export default UsedForms;
