import * as React from "react";

import Button from "../atoms/button";
import Heading from "../atoms/heading";
import List from "../atoms/list";
import Section from "../atoms/section";
import Spinner from "../atoms/spinner";
import { Clickable, Label, Patience } from "../business/interfaces";
import SentForm, { Props as SentFormProps } from "./sentForm";
import Wrap from "../atoms/wrap";

export type Props = Label & {
  sentForms?: SentFormProps[];
  more?: Label & Clickable;
};

const SentForms = React.memo<Patience & Props>(
  ({ label, sentForms, more, patience }) => (
    <Section subType="forms-sent">
      <Heading level="h2" label={label} />
      <List subType="forms-sent">
        {sentForms === undefined ? (
          <Spinner label={patience} />
        ) : (
          sentForms.map((form) => (
            <SentForm key={form.download.href} {...form} />
          ))
        )}
      </List>
      {sentForms !== undefined && more !== undefined && (
        <Wrap subType="more">
          <Button subType="more" {...more} />
        </Wrap>
      )}
    </Section>
  )
);

export default SentForms;
