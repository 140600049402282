import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { NamedExoticComponent } from "react";
import ReactDOM from "react-dom";
import { Config, DefaultConfig } from "./business/models";
import { AppProps } from "./containers/App";
import boot from "./boot";
import LoginApp from "./LoginApp";
import OverviewApp from "./OverviewApp";
import { Action, Store } from "redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
  }
}

function render(
  element: HTMLElement | null,
  Component: NamedExoticComponent<AppProps>,
  store: Store<{}, Action<any>>
) {
  if (element != null) {
    const configJson = element.dataset["klantportaal"];
    const config =
      configJson === undefined
        ? DefaultConfig
        : (JSON.parse(configJson) as Config);
    ReactDOM.render(<Component store={store} config={config} />, element);
  }
}

(function () {
  const autosubmit = document.querySelector(
    "form.autosubmit"
  ) as HTMLFormElement | null;
  if (autosubmit) {
    autosubmit.submit();
    return;
  }

  const login = document.getElementById("login");
  const overview = document.getElementById("overview");

  if (login === null && overview === null) {
    return;
  }

  const store = boot();

  render(login, LoginApp, store);
  render(overview, OverviewApp, store);
})();
