import * as React from "react";
import { Label } from "../business/interfaces";
import { superClass } from "../util/classNames";

export type Props = Label;

const Spinner = React.memo<Props>(({ label }) => (
  <div className={superClass("spinner")}>{label}</div>
));

export default Spinner;
