import fetch from "isomorphic-fetch";
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import { asyncLifecycle } from "./lifecycle";
import { Snack } from "./models";

const jsonConfig = {
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
};
const jsonFetch = async (
  method: string,
  pathAndQuery: string,
  body?: string
) => {
  try {
    const response = await fetch(`/api/${pathAndQuery}`, {
      ...jsonConfig,
      method,
      body,
      credentials: "same-origin",
    });
    if (response.status !== 200) {
      return { exception: `Response status ${response.status}` };
    }
    const payload = await response.json();
    return { payload };
  } catch (e) {
    return { exception: e };
  }
};
const jsonGet = (pathAndQuery: string) => jsonFetch("get", pathAndQuery);
const jsonGetter = (pathAndQuery: string) => () => jsonGet(pathAndQuery);

const getReference = jsonGetter("reflist/klantportaal");
const getAll = jsonGetter("sentforms");
const getSaved = jsonGetter("savedforms/5");
const getSent = jsonGetter("sentforms/5");
const getUsed = jsonGetter("lastusedforms/5");

let errorId = 0;
const interpretError: (action: AnyAction, id: number) => Snack = (
  { type }: AnyAction,
  id: number
) => {
  if (/_ERROR$/.test(type)) {
    return {
      id,
      text: `Er is een fout opgetreden. Foutcode: ${type}`,
    };
  }

  // This should be unreachable
  return {
    id,
    text: `Onduidelijke melding. Actiecode: ${type}`,
  };
};

function* showError(action: AnyAction) {
  const error = interpretError(action, ++errorId);
  yield put({ type: "ERROR_SHOW", payload: error });
  yield call(delay, 2000);
  yield put({ type: "ERROR_HIDE", payload: error });
}

function* initLifecycle() {
  yield takeEvery(
    "REFERENCE_REQUIRE",
    asyncLifecycle("REFERENCE", getReference)
  );
  yield takeEvery("ALL_REQUIRE", asyncLifecycle("ALL", getAll));
  yield takeEvery("SAVED_REQUIRE", asyncLifecycle("SAVED", getSaved));
  yield takeEvery("SENT_REQUIRE", asyncLifecycle("SENT", getSent));
  yield takeEvery("USED_REQUIRE", asyncLifecycle("USED", getUsed));
  yield takeEvery(({ type }: AnyAction) => /^_ERROR$/.test(type), showError);
}

function* watchAndLog() {
  yield takeEvery("*", function* logger(action) {
    const state = (yield select()) as Record<string, object>;

    console.log("action", action);
    console.log("state after", state);
  });
}

export const sagas = function* sagas() {
  const inits = [initLifecycle];
  if (process.env.NODE_ENV === "development") {
    inits.push(watchAndLog);
  }

  yield all(inits.map((init) => init()));
};
