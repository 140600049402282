import * as React from "react";

import { DateTime, Href, Label } from "../business/interfaces";

import Item from "../atoms/item";
import Link from "../atoms/link";
import Time from "../atoms/time";

export type Props = Href & Label & DateTime;

const SavedForm = React.memo<Props>(({ href, label, dateTime }) => (
  <Item subType="form-saved">
    <Link subType="form" href={href} label={label} />
    <Time subType="saved" dateTime={dateTime} />
  </Item>
));

export default SavedForm;
