import * as React from "react";

import { Href, Label } from "../business/interfaces";

import Item from "../atoms/item";
import Link from "../atoms/link";

export type Props = Href & Label;

const UsedForm = React.memo<Props>(({ href, label }) => (
  <Item subType="form-used">
    <Link subType="form" href={href} label={label} />
  </Item>
));

export default UsedForm;
