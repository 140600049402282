import * as React from "react";

import { Children, SubType } from "../business/interfaces";

import { subClass } from "../util/classNames";

export type Props = SubType & Children;

const List = React.memo<Props>(({ subType, children }) => (
  <ul className={subClass("list", subType)}>{children}</ul>
));

export default List;
