import * as React from "react";
import { Location } from "history";
import { RouteProps } from "react-router-dom";

class Reload extends React.PureComponent<RouteProps> {
  componentDidMount() {
    Reload.redirect(this.props.location);
  }

  componentDidUpdate() {
    Reload.redirect(this.props.location);
  }

  static redirect(location?: Location) {
    if (
      location === undefined ||
      (location.pathname.indexOf("reload=true") < 0 &&
        location.search.indexOf("reload=true") < 0)
    ) {
      return;
    }
    const hash = window.location.href.indexOf("#");
    if (hash < 0) {
      window.location.reload();
    } else {
      window.location.href = window.location.href.substring(0, hash);
    }
  }

  render = () => null;
}

export default Reload;
