import * as React from "react";
import { HeadingLevel, Label } from "../business/interfaces";

export type Props = HeadingLevel & Label;

const Heading = React.memo<Props>(({ label, level: Heading }) => (
  <Heading>{label}</Heading>
));

export default Heading;
