import * as React from "react";
import { connect } from "react-redux";
import LoginPage, { Props as LoginProps } from "../pages/loginPage";
import Spinner from "../atoms/spinner";
import { ReferenceList, StoreModel } from "../business/models";

type Props = { login?: LoginProps };

const Component = React.memo<Props>(({ login }) =>
  login === undefined ? (
    <Spinner label="Even geduld, het klantportaal wordt geladen." />
  ) : (
    <LoginPage {...login} />
  )
);

const toLoginProps: (reference: ReferenceList) => LoginProps = (reference) => {
  const htmlOrDefault = (alias: string) =>
    alias in reference ? reference[alias].html : alias;
  const login = (method: string) => ({
    label: htmlOrDefault(`login_${method}_title`),
    html: htmlOrDefault(`login_${method}_body`),
    login: {
      label: htmlOrDefault(`login_${method}_login`),
      onClick: () => (document.location.href = `?login=${method}`),
    },
  });
  const request = (method: string) => ({
    label: htmlOrDefault(`request_${method}_title`),
    html: htmlOrDefault(`request_${method}_body`),
    request: {
      label: htmlOrDefault(`request_${method}_request`),
      href: htmlOrDefault(`request_${method}_link`),
    },
  });
  return {
    login: {
      label: htmlOrDefault("login_title"),
      html: htmlOrDefault("login_intro"),
      digid: login("digid"),
      eherkenning: login("eherkenning"),
      eidas: login("eidas"),
      alerts: {
        DigidCancel: htmlOrDefault("DigidCancel"),
        DigidError: htmlOrDefault("DigidError"),
        EherkenningCancel: htmlOrDefault("EherkenningCancel"),
        EherkenningError: htmlOrDefault("EherkenningError"),
      },
    },
    request: {
      label: htmlOrDefault("request_title"),
      digid: request("digid"),
      eherkenning: request("eherkenning"),
      eidas: request("eidas"),
    },
  };
};

const emptyObject = Object.freeze({});
const LoginContainer = connect(({ reference: { value } }: StoreModel) =>
  value === undefined ? emptyObject : { login: toLoginProps(value) }
)(Component);

export default LoginContainer;
