import * as React from "react";

import { Children, SubType } from "../business/interfaces";

import { subClass } from "../util/classNames";

export type Props = SubType & Children;

const Item = React.memo<Props>(({ subType, children }) => (
  <li className={subClass("item", subType)}>{children}</li>
));

export default Item;
