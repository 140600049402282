import * as React from "react";

import { DateTime, FormReference, Href, Label } from "../business/interfaces";

import Item from "../atoms/item";
import Link from "../atoms/link";
import Time from "../atoms/time";
import Wrap from "../atoms/wrap";

export type Props = Label &
  DateTime & {
    add: Href & Label;
    cancel: Href & Label;
    download: Href & Label;
    formReference?: FormReference;
  };

const SentForm = React.memo<Props>(
  ({ label, formReference, dateTime, add, cancel, download }) => (
    <Item subType="form-sent">
      <Wrap subType="form">{label}</Wrap>
      {formReference ? (
        <Wrap subType="reference">
          {formReference.label} {formReference.value}
        </Wrap>
      ) : undefined}
      <Link subType="download" {...download} />
      <Link subType="add" {...add} />
      <Link subType="cancel" {...cancel} />
      <Time subType="sent" dateTime={dateTime} />
    </Item>
  )
);

export default SentForm;
