import { AnyAction } from "redux";

const emptyArray = Object.freeze([]);

export const errorReducer =
  (prefix: string) =>
  (
    state: Array<any> | ReadonlyArray<any> | undefined,
    { type, payload }: AnyAction
  ) => {
    const [p, e] = type.split("_");
    const safeState = state || emptyArray;
    if (p !== prefix) {
      return safeState;
    }

    switch (e) {
      case "SHOW":
        return [...safeState, payload];
      case "HIDE": {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...tail] = safeState;
        return tail;
      }
      default:
        return safeState;
    }
  };
