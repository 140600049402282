import * as React from "react";
import Wrap from "../atoms/wrap";
import BackZone, { Props as BackProps } from "../zones/backZone";
import SentZone, { Props as SentProps } from "../zones/sentZone";

export interface Props {
  back: BackProps;
  sent: SentProps;
  require: () => void;
}

class SentPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    props.require();
  }

  render = () => {
    const { back, sent } = this.props;
    return (
      <Wrap subType="page-sent">
        <BackZone {...back} />
        <SentZone {...sent} />
      </Wrap>
    );
  };
}

export default SentPage;
