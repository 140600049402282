import * as React from "react";
import { DateTime, SubType } from "../business/interfaces";
import { formatDate } from "../util/date";
import { subClass } from "../util/classNames";

export type Props = DateTime & SubType;

const Time = React.memo<Props>(({ dateTime, subType }) => (
  <time className={subClass("time", subType)} dateTime={dateTime.toISOString()}>
    {formatDate(dateTime)}
  </time>
));

export default Time;
