import * as React from "react";

import { Href, Html, Label, SubType } from "../business/interfaces";

import Heading from "../atoms/heading";
import RichContent from "../atoms/richContent";
import Section from "../atoms/section";
import Link from "../atoms/link";

export type DataProps = Label &
  Html & {
    request: Href & Label;
  };

export type Props = DataProps & SubType;

const RequestSection = React.memo<Props>(
  ({ label, html, request, subType }) => (
    <Section subType={`request-${subType}`}>
      <Heading level="h3" label={label} />
      <RichContent subType="info" html={html} />
      <Link subType="request" {...request} />
    </Section>
  )
);

export default RequestSection;
