import * as React from "react";
import { Action, Store } from "redux";
import { Config } from "./business/models";
import App from "./containers/App";
import LoginContainer from "./containers/loginContainer";

interface StoreProps {
  store: Store<{}, Action>;
  config: Config;
}

const LoginApp = React.memo<StoreProps>((props) => (
  <App {...props}>
    <LoginContainer />
  </App>
));

export default LoginApp;
